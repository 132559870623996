import { RouteInfo } from './sidebar.metadata';
import { RolesEnum } from 'src/app/@Common/enum/roles.enum';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '', title: 'Inicio', icon: 'zmdi zmdi-home', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/conade/inicio', title: 'Inicio', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: true, colaborador: true, director: true,
        subdirector: true,
        jefeDepartamento: true}
    ],
    coordinador: true,
    recepcion: true,
    colaborador: true,
    director: true,
    subdirector: true,
    jefeDepartamento: true
  },
  {
    path: '', title: 'Asunto', icon: 'fa fa-archive', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/conade/asuntos/agregar', title: 'Nuevo asunto', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: true, colaborador: false, director: true,
        subdirector: true,
        jefeDepartamento: true},
      {
        path: '/conade/asuntos/lista', title: 'Todos los asuntos', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true, director: true,
        subdirector: true,
        jefeDepartamento: true},
        {
          path: '/conade/asuntos/recepcion/lista', title: 'Asuntos registrados', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: false, recepcion: true, colaborador: false},
      {
        path: '/conade/asuntos/pendientes', title: 'Pendientes', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true, director: true,
        subdirector: true,
        jefeDepartamento: true},
      {
        path: '/conade/asuntos/visor', title: 'Visor', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true, director: true,
        subdirector: true,
        jefeDepartamento: true},
      { path: '/conade/asuntos/borrador', title: 'Borradores', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: false, recepcion: true, colaborador: false},
      {path: '/conade/asuntos/lista-enlace', title: 'Enlace', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: false, recepcion: false, colaborador: false, director: false, subdirector: false, jefeDepartamento: false}
      /* { path: '/conade/asuntos/historico', title: 'Histórico', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true} */

    ],
    coordinador: true, recepcion: true, colaborador: true, director: true,
    subdirector: true,
    jefeDepartamento: true
  }
  ,
  {
    path: '/conade/dashboard/tablero', title: 'Tablero', icon: 'fa fa-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [],
    coordinador: false, recepcion: false, colaborador: false, director: false,
    subdirector: true,
    jefeDepartamento: false
  },
  {
    path: '/conade/dashboard/direccion', title: 'Tablero general', icon: 'fa fa-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false,
    submenu: []
  }
  ,
  {
    path: '', title: 'Usuarios', icon: 'fa fa-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/usuarios/agregar', title: 'Alta usuario', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: false, recepcion: false, colaborador: false, admin: true},
      { path: '/conade/usuarios/administracion', title: 'Administración usuarios', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: false, recepcion: false, colaborador: false, admin: true}
    ],
    coordinador: false, recepcion: false, colaborador: false, admin: true
  },
  {
    path: '/conade/enlaces', title: 'Enlaces', icon: 'fa fa-link', class: '', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [],
    coordinador: false,
    recepcion: false,
    colaborador: false,
    director: false,
    subdirector: false,
    jefeDepartamento: false
  }
  /* ,
  {
    path: '', title: 'Catálogos', icon: 'fa fa-book', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/catalogos/dias-festivos', title: 'Dias festivos', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: false },
      { path: '/conade/catalogos/respuestas', title: 'Respuestas', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: false},
      { path: '/conade/catalogos/tipo-documento', title: 'Tipo documento', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: false}
    ],
    coordinador: true, recepcion: false, colaborador: false
  } */

];
